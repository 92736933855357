import client from "./requestclient";

const properties = {
  fetchForRent: async () => {
    return await client({ URL_PATH: `fetchpropertiesrandomly`, method: "GET" });
  },

  fetchForSale: async () => {
    return await client({ URL_PATH: `fetchpropertiesforsale`, method: "GET" });
  },

  fetchPropertyType: async () => {
    return await client({ URL_PATH: `fetchpropertytypes`, method: "GET" });
  },
  fetchPropertySubType: async id => {
    return await client({
      URL_PATH: `fetchpropertysubtypesbytypeid/${id}`,
      method: "GET"
    });
  },
  fetchBusinessTypes: async () => {
    return await client({
      URL_PATH: "newfetchbusinesstypes",
      method: "GET"
    });
  },
  fetchPropertyStatuses: async () => {
    return await client({
      URL_PATH: `fetchpropertystatuses`,
      method: "GET"
    });
  },
  fetchStates: async () => {
    return await client({
      URL_PATH: `fetchstatesbycountryid/161`,
      method: "GET"
    });
  },
  fetchPropertyAwarenessTypes: async () => {
    return await client({
      URL_PATH: "fetchpropertyawarenestypes",
      method: "Get"
    });
  },
  verifyEmail: async email => {
    return await client({
      URL_PATH: `checkuserwithemailexist/${email}`,
      method: "GET"
    });
  },
  registerIdentifiedProperty: async body => {
    return await client({
      URL_PATH: "processaddpropertyrepository",
      method: "POST",
      body: body
    });
  }
};

export default properties;
